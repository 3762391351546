import * as DesignSystem from "components/design-system"
import Holder from "components/holder"
import Section from "components/section"
import { graphql, Link } from "gatsby"
import * as React from "react"
import styled from "styled-components"
import Layout from "templates/layout"

export const BlogItem = styled.div`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #e4e8eb;
  height: inherit;

  h5, p {
    padding-left: 16px;
    padding-right: 16px;
  }

  p {
    margin-top: 16px;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: normal;
    color: #696969;
  }
`

export const BlogItemContainer = styled(Link)`
  height: 100%;

  &:hover {
    text-decoration: none;
  }
`

export const StyledImage = styled.img`
  max-width: 100%;
`

interface Edge {
  node: {
    fields: {
      slug: string
    }
    frontmatter: {
      hero: string
      date: string
      path: string
      title: string
    }
    body: string
  }
}

interface BlogProps {
  data: {
    allMdx: {
      edges: Edge[]
    }
  }
}

export default class Blog extends React.Component<BlogProps> {
  render() {
    const { edges } = this.props.data.allMdx

    return (
      <Layout alwaysOnHeader={true} title="Blog | Stackchat">
        <Section padding="large" topPaddingOnly={true}>
          <Holder width={12} className="text-center">
            <DesignSystem.H1>Blog</DesignSystem.H1>
          </Holder>
        </Section>

        <Section padding="regular">
          {
            edges
              .filter(edge => edge.node.frontmatter.hero.length > 0)
              .map((edge, index) => {
                return (
                  <Holder width={4} key={index}>
                    <BlogItemContainer to={edge.node.frontmatter.path}>
                      <BlogItem>
                        <StyledImage src={edge.node.frontmatter.hero} />
                        <DesignSystem.P>{edge.node.frontmatter.date}</DesignSystem.P>
                        <DesignSystem.H5>{edge.node.frontmatter.title}</DesignSystem.H5>
                      </BlogItem>
                    </BlogItemContainer>
                  </Holder>
                )
              })
          }
        </Section>
      </Layout>
    )
  }
}

export const query = graphql`
{
  allMdx(
    sort: {fields: [frontmatter___date], order: DESC}, 
    filter: {frontmatter: {path: {regex: "/^\\/blog/i"}}}
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          hero
          path
          title
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
}
`
